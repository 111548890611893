<template>
  <section>
    <van-empty
      image="error"
      description="无权访问"
    />
  </section>
</template>

<script>
export default {
  name: 'Error401'
}
</script>

<style></style>
